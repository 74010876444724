<template>
  <v-container fluid class="pt-6 mt-6">
    <v-row justify="center">
      <v-col md="6" lg="5">
        <div v-if="!loader" class="services">
          <ServicesHeader
            v-if="category"
            :category="category"
            :loading="searchInputLoading"
            @debouncedInput="searchService"
          />
          <template v-if="!isCustomServices">
            <ServiceItem
              v-for="(service, serviceIndex) in searchServiceList"
              :key="`s-${serviceIndex}${rerenderList}`"
              :service="service"
              :categoryId="categoryId"
              :roomId="roomId"
              :units="units"
              @updateQuotaRoomService="$emit('updateQuotaRoomService')"
              @deleteQuatoRoomService="$emit('deleteQuatoRoomService')"
              @updateQuotaRoomProducts="$emit('updateQuotaRoomProducts')"
              @deleteRoomProduct="$emit('deleteRoomProduct')"
              @cloneService="(payload) => cloneService(serviceIndex, payload)"
            />
          </template>
          <template v-else>
            <ServiceCustomItem
              v-for="(serviceCustom, serviceCustomIndex) in searchServiceList"
              :key="`service-custom-${serviceCustomIndex}`"
              :service="serviceCustom"
              :categoryId="categoryId"
              :roomId="roomId"
              @updateQuotaRoomService="$emit('updateQuotaRoomService')"
              @updateCustomService="
                (payload) => $emit('updateCustomService', payload)
              "
            />
          </template>
        </div>
        <div v-if="loader" class="text-center pt-2">
          <v-progress-circular
            :size="40"
            :width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import lastIndexOf from "lodash.lastindexof";

import { CategoryService } from "@/services/category.service.js";
import getCurrentProjectOwnerId from "@/mixins/getCurrentProjectOwnerId";
export default {
  name: "Services",
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
    units: {
      type: Array,
      required: true,
    },
    roomProducts: {
      type: Array,
      required: true,
    },
    isCustomServices: {
      type: Boolean,
    },
    roomCustomCategories: {
      type: Array,
      default: () => [],
    },
    customServices: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [getCurrentProjectOwnerId],
  components: {
    ServicesHeader: () => import("./ServicesHeader/ServicesHeader.vue"),
    ServiceItem: () => import("./ServiceItem/ServiceItem.vue"),
    ServiceCustomItem: () => import("./ServiceItem/ServiceCustomItem.vue"),
  },
  data() {
    return {
      loader: false,
      searchInputLoading: false,
      selectedServices: [],
      category: null,
      searchInput: "",
      rerenderList: 0,
    };
  },
  computed: {
    ...mapState({
      roomServices(state) {
        return state.services.roomServices;
      },
      services(state) {
        return state.services.services;
      },
    }),
    servicesList: {
      get() {
        if (this.isCustomServices) {
          return [...this.customServices].map((s) => {
            return {
              ...s,
              roomService:
                this.roomServices.find((roomService) => {
                  return roomService.service_id === s.id;
                }) || null,
            };
          });
        } else {
          return this.$store.getters["services/list"](
            +this.$route.params.categoryId
          );
        }
      },
      set(newVal) {
        const index = lastIndexOf(
          this.servicesList.map((s) => s.id),
          newVal.id
        );
        this.servicesList.splice(index + 1, 0, newVal);
      },
    },
    searchServiceList: {
      get() {
        let list = [];
        if (this.searchInput === "") {
          list = this.servicesList;
        } else {
          list = this.servicesList.filter(({ name, roomService }) => {
            let text = name?.toLowerCase();
            return roomService || text?.includes(this.searchInput);
          });
        }
        return list;
      },
    },
  },
  created() {
    if (this.isCustomServices) {
      this.category = this.roomCustomCategories[0];
      return;
    }
    this.loader = true;
    Promise.all([
      this.getCategory(),
      this.dataServicesOfCategory({
        catId: +this.categoryId,
        roomId: this.roomId,
      }),
    ])
      .catch(() => {
        this.$store.commit(
          "snackbarModule/SET_ERROR_API",
          this.$t("snackbar.error.base")
        );
      })
      .finally(() => (this.loader = false));
  },
  methods: {
    ...mapActions({
      dataServicesOfCategory: "services/dataServicesOfCategory",
    }),
    searchService(payload) {
      this.searchInputLoading = true;
      setTimeout(() => {
        this.searchInput = payload;
        this.searchInputLoading = false;
      }, 500);
    },
    getCategory() {
      return CategoryService.getCategoryByid(+this.categoryId).then(
        ({ data }) => {
          this.category = data;
        }
      );
    },
    async cloneService(cloneIndex, combination) {
      await this.dataServicesOfCategory({
        catId: +this.categoryId,
        roomId: this.roomId,
      });
      let service = JSON.parse(JSON.stringify(this.servicesList[cloneIndex]));
      service.roomService.id = null;
      service.roomService.service_combination = combination || null;
      service.roomService.service_combination_id =
        (combination && combination.id) || null;
      service.roomService.amount = null;
      service.roomProduct = null;
      this.servicesList = service;
      this.rerenderList++;
    },
  },
};
</script>

<style></style>
